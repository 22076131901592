import { useState, useEffect } from "react";
import styles from "./ControlWindow.module.css";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ClipLoader from "react-spinners/ClipLoader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { getPickupPoints } from "../../../env/APIManager.js";
import { MarkerF, InfoWindowF, OverlayViewF } from "@react-google-maps/api";
import storeIcon from "../../../assets/StoreIcon.png";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ControlWindow({
	tokenId,
	checked,
	runnerChecked,
	unfilteredTasks,
	setRunnerChecked,
	setChecked,
	destinationMarker,
	checkLoaded,
	setCheckLoaded,
}) {
	const [visible, setVisible] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [pickupPoints, setPickupPoints] = useState(null);
	const [productTypes, setProductTypes] = useState(null);
	const [intervalId, setIntervalId] = useState(null);
	const [windowIndex, setWindowIndex] = useState({ show: null });
	const [pair, setPair] = useState(null);
	const [infoVisible, setInfoVisible] = useState({});

	const { push } = useHistory();

	const handleClick = () => {
		setVisible(!visible);
	};

	const handlePickupChange = (event) => {
		setChecked({
			...checked,
			pickup: event.target.checked,
		});
		apiSwitch(event.target.checked);
	};

	const handleDropoffChange = (event) => {
		setChecked({
			...checked,
			dropoff: event.target.checked,
		});
	};

	const pickupClick = () => {
		setChecked({
			...checked,
			pickup: !checked.pickup,
		});
		apiSwitch(!checked.pickup);
	};

	const dropoffClick = () => {
		setChecked({
			...checked,
			dropoff: !checked.dropoff,
		});
	};

	const handleRunnerChange = (event) => {
		const isChecked = Object?.keys(runnerChecked)?.find(
			(key) => runnerChecked[key] === true
		);
		if (isChecked !== event.target.name) {
			setRunnerChecked({
				...runnerChecked,
				all: false,
				online: false,
				offline: false,
				[event.target.name]: event.target.checked,
			});
			setCheckLoaded(true);
		}
	};

	const openClick = (index) => {
		setIsOpen(true);
		setWindowIndex({ show: index });
	};

	const skuPair = (productType) => {
		const data = {};
		if (productType) {
			Object?.keys(productType)?.forEach((value) => {
				productType[value].skus.forEach((sku) => {
					if (sku in data) data[sku].push(value);
					else data[sku] = [value];
				});
			});
		}

		return data;
	};

	const skuMatcher = (point) => {
		const definedSkus = point?.skus?.filter((sku) => pair[sku] !== undefined);
		const imageElement = [];
		const seen = new Set();
		const titleElement = definedSkus?.map((sku) => {
			const keys = pair[sku];
			const titles = keys?.map((productId, index) => {
				if (!seen.has(productId)) {
					seen.add(productId);
					if (productTypes[productId]?.image)
						imageElement.push(
							<img
								key={imageElement.length}
								style={{ width: "23px" }}
								src={productTypes[productId].image}
							></img>
						);
					return (
						<div key={productId + 1 + "dsdfds"}>
							{productTypes[productId].title}
						</div>
					);
				}
			});
			return titles;
		});

		const formattedElement = (
			<>
				<div className={styles.productsContainer}>{titleElement}</div>
				<div className={styles.imageContainer}>{imageElement}</div>
			</>
		);

		return formattedElement;
	};

	const skuImgMatch = (point) => {
		const definedSkus = point?.skus?.filter((sku) => pair[sku] !== undefined);
		const seen = new Set();
		const imageElement = definedSkus?.map((sku) => {
			const keys = pair[sku];
			const images = keys?.map((productId, index) => {
				if (!seen.has(productId)) {
					seen.add(productId);
					if (productTypes[productId]?.image)
						return (
							<img
								key={index}
								style={{ width: "23px" }}
								src={productTypes[productId]?.image}
							></img>
						);
				}
			});

			return images;
		});

		const formattedElement = (
			<>
				<div className={styles.imageContainer}>{imageElement}</div>
			</>
		);

		return formattedElement;
	};

	// if the checkbox is set to false the interval will be cleared.
	const apiSwitch = (target) => {
		if (target) {
			const apiInterval = setInterval(() => {
				getPickupPoints(tokenId).then((response) => {
					setPickupPoints(response?.data?.pickupPoints);
					setProductTypes(response?.data?.productType);
					if (pair === null) {
						setPair(skuPair(response?.data?.productType));
					}
				});
			}, 5000);
			setIntervalId(apiInterval);
		} else if (intervalId) {
			clearInterval(intervalId);
			setIntervalId(null);
		}
	};

	useEffect(() => {
		if (checked.pickup) {
			apiSwitch(true);
		}
	}, []);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.checkboxStyle} onClick={pickupClick}>
					<div className={[styles.innerContainer, styles.noselect].join(" ")}>
						<div className={styles.mainTitle}>Vendor Stores</div>
						<Checkbox
							name="Vendor Stores"
							size="small"
							checked={checked.pickup}
							onChange={handlePickupChange}
						/>
					</div>
				</div>
				<div className={styles.checkboxStyle} onClick={dropoffClick}>
					<div className={[styles.innerContainer, styles.noselect].join(" ")}>
						<div className={styles.mainTitle}>Delivery Dropoffs</div>
						<Checkbox
							name="Delivery Dropoffs"
							size="small"
							checked={checked.dropoff}
							onChange={handleDropoffChange}
						/>
					</div>
				</div>
				<div className={styles.dropDownStyle}>
					<div
						className={[styles.innerContainer, styles.noselect].join(" ")}
						onClick={handleClick}
					>
						<div className={styles.mainTitle}>G-Runner</div>
						<div
							className={
								!visible ? styles.buttonStyles : styles.buttonStylesOpen
							}
						>
							{checkLoaded ? (
								<ClipLoader color="#6C40FC" size={31}></ClipLoader>
							) : (
								<IconButton>
									<ArrowDropDownIcon
										sx={{ color: "black", fontSize: "15px" }}
									/>
								</IconButton>
							)}
						</div>
					</div>
					{visible ? (
						<div className={styles.checkboxContainer}>
							<div className={styles.innerCheckContainer}>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												name="all"
												size="small"
												checked={runnerChecked.all}
												onChange={handleRunnerChange}
											/>
										}
										label="All"
									/>
									<FormControlLabel
										control={
											<Checkbox
												name="online"
												size="small"
												checked={runnerChecked.online}
												onChange={handleRunnerChange}
											/>
										}
										label="Online"
									/>
									<FormControlLabel
										control={
											<Checkbox
												name="offline"
												size="small"
												checked={runnerChecked.offline}
												onChange={handleRunnerChange}
											/>
										}
										label="Offline"
									/>
								</FormGroup>
							</div>
						</div>
					) : null}
				</div>
				<Link
					className={styles.crmRedirect}
					to="/crm"
					target="_blank"
				>
					CRM{" "}
				</Link>
			</div>

			{checked.pickup &&
				pickupPoints &&
				pickupPoints?.map((point, index) => (
					<OverlayViewF
						mapPaneName="markerLayer"
						position={{ lat: point?.latitude, lng: point?.longitude }}
						key={index + 1}
					>
						<div className={styles.markerShadow}>
							<MarkerF
								key={index + 1}
								position={{ lat: point?.latitude, lng: point?.longitude }}
								onClick={() => {
									openClick(index);
								}}
								onLoad={() => {
									setInfoVisible((prev) => ({ ...prev, [index]: true }));
								}}
								onUnmount={() => {
									setInfoVisible((prev) => ({ ...prev, [index]: false }));
								}}
								icon={{
									url: storeIcon,
									scaledSize: {
										width: 35,
										height: 35,
									},
								}}
							>
								{point?.name && windowIndex.show !== index && (
									<InfoWindowF
										zIndex={11}
										options={{ disableAutoPan: true }}
										position={{ lat: point?.latitude, lng: point?.longitude }}
									>
										<div
											className={styles.infoContainer}
											onClick={() => {
												openClick(index);
											}}
										>
											<div className={styles.infoCard}>
												<div>{point.name}</div>
											</div>
											{pair && productTypes && skuImgMatch(point)}
										</div>
									</InfoWindowF>
								)}

								{infoVisible[index] === true &&
									isOpen &&
									windowIndex.show === index && (
										<InfoWindowF
											key={index + 1}
											zIndex={12}
											options={{ disableAutoPan: true }}
										>
											<div>
												<div className={styles.closeButton}>
													<IconButton
														// disableRipple
														size="small"
														onClick={() => {
															setIsOpen(false);
															setWindowIndex({ show: null });
														}}
													>
														<CloseIcon
															sx={{ color: "white", fontSize: "15px" }}
														/>
													</IconButton>
												</div>
												<div className={styles.runnerContainer}>
													<div className={styles.name}>{point?.name}</div>
													<div>{point?.fullAddress}</div>
												</div>
												{pair && productTypes && skuMatcher(point)}
											</div>
										</InfoWindowF>
									)}
							</MarkerF>
						</div>
					</OverlayViewF>
				))}
		</>
	);
}
