import * as React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "../../CRM/components/OrderDetails.module.css"

export default function ConfirmationModal({
	title,
	description,
	onConfirm,
	onCancel,
	show
}) {
	return (
		<Modal show={show} onHide={onCancel}>
			{title && (
				<Modal.Header closeButton>
					<Modal.Title style={{fontSize:"18px"}}>{title}</Modal.Title>
				</Modal.Header>
			)}
			{description && <Modal.Body>{description}</Modal.Body>}
			<Modal.Footer>

				<button
					className={styles.cancelBtnLevel}
					onClick={onCancel}
				>
					Cancel
				</button>
				<button
					onClick={onConfirm}
					className={styles.actionBtnLevel}
				>
					OK
				</button>

			</Modal.Footer>
		</Modal>
	);
}
