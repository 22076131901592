import { prodLink, devLink } from "../../APIManager_";

const isProd = false; // true: real data, false: development data

const URL = isProd ? `${prodLink}/grunnerv3` : `${devLink}/grunnerv3`;

export function getAllGRunners(tokenId) {
  const url = `${URL}/admin/commands/grunners/all`;
  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };
  const data = fetch(url, auth)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });
  return data;
}

export function getGRunner(grunnerId, tokenId) {
  const url = `${URL}/admin/commands/grunners/single/${grunnerId}`;
  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };
  const data = fetch(url, auth)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });

  return data;
}

export function getAllTasks(tokenId) {
  const url = `${URL}/admin/commands/tasks/all`;
  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };
  const data = fetch(url, auth)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });
    console.log(data);
  return data;
}

export function userLogin(email, password) {
  const url = `${URL}/admin/users/login`;
  const data = fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ email: email, password: password }),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });

  return data;
}

export function addTask(taskId, grunnerId, tokenId) {
  const url = `${URL}/admin/commands/tasks/connection`;
  var fetchOBJ = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenId}`,
    },
    method: "POST",
    body: JSON.stringify({ taskId: taskId, grunnerId: grunnerId }),
  };
  const data = fetch(url, fetchOBJ)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });

  return data;
}

export function removeTask(taskId, grunnerId, tokenId) {
  const url = `${URL}/admin/commands/tasks/removal`;
  var fetchOBJ = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenId}`,
    },
    method: "POST",
    body: JSON.stringify({ taskId: taskId, grunnerId: grunnerId }),
  };
  const data = fetch(url, fetchOBJ)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });

  return data;
}

export function getRunnerHistory(grunnerId, tokenId) {
  const url = `${URL}/admin/commands/grunners/history/single/${grunnerId}`;
  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };
  const data = fetch(url, auth)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });

  return data;
}

export function getPickupPoints(tokenId) {
  const url = `${URL}/admin/commands/pickup_points/all`;
  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };
  const data = fetch(url, auth)
    .then((response) => {
      return response.json();
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return null;
    });

  return data;
}
