import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

import {
  getGrunnerUserDetails
} from "../../env/APIManager";
import styles from "./GrunnerUserDetails.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";
import { ToastContainer, toast } from "react-toastify";
import GrunnerUserTasks from "./GrunnerUserTasks"

import CRMNote from "../crm-notes/crmNote";

const ExpandableDiv = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const showStyle = {
    height: "auto",
  };
  const hideStyle = {
    paddingTop: "4px",
    paddingBottom: "4px",
  };

  return (
    <div
      style={isExpanded ? showStyle : hideStyle}
      className={styles.collapseSection}
      key={title}
    >
      <div onClick={handleToggle} className={styles.mainTitleContainer}>
        {isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
        <div className={styles.mainTitle}>{title}</div>
      </div>

      {isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
    </div>
  );
};
function GrunnerUserDetails() {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [tasks, setTasks] = useState([]);
  const [token, setToken] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const key = params.get("key");
  const nextPageToken = params.get("nextPageToken");

  const handleGoBack = () => {
    if (nextPageToken && key) {
      history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
    } else if (key && key !== undefined && key !== null) {
      history.push(`/crm?key=${key}`);
    } else {
      history.push(`/crm`);
    }
  };
  let history = useHistory();

  async function getUser(token, id) {
    setLoading(true);

    let data = await getGrunnerUserDetails(token, id);

    if (data?.code === "SUCCESS") {
      setUserDetails(data?.data);
      setLoading(false);
    } else if (data?.data?.code === "UNAUTHORIZED") {
      localStorage.clear();
      setLoading(false);
      toast.error(
        "You have been logged out. This may have been caused by using more than one device or browser"
      );
      history.push({ pathname: "/login" });
    } else {
      toast.error("Something went wrong, please try again later");
      setLoading(false);
    }
  }
 

  useEffect(() => {
    let token =
      localStorage.getItem("token") == null ||
      localStorage.getItem("token") == undefined ||
      localStorage.getItem("token") == ""
        ? ""
        : JSON.parse(localStorage.getItem("token"));
    setToken(token);

    if (!id) {
      if (nextPageToken && key) {
        history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
      } else {
        history.push(`/crm?key=${key}`);
      }
      return;
    }

    getUser(token, id);
    
  }, []);

  

  return (
    <>
      <ToastContainer limit={1} />
      <div className={styles.mainContainer}>
        {loading ? (
          <div className={styles.clipLoader}>
            <ClipLoader color="#000" size={50} />
          </div>
        ) : userDetails && Object.keys(userDetails)?.length > 0 ? (
          <>
            <div className={styles.headerContainer}>
              <ArrowBackIcon
                fontSize="large"
                style={{ marginLeft: "-5px", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              <div className={styles.titleContainer}>
                <div className={styles.pageTitle}>
                  User #{id?.substring(id?.length - 5)}{" "}
                </div>
              </div>
            </div>
            {/* user information section */}
            <ExpandableDiv title="User Information">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyContainer}>
                    <div className={styles.bodyTitle}>Created</div>
                    <div>
                      {userDetails?.createdAt
                        ? new Date(userDetails?.createdAt).toLocaleDateString(
                            "en-US"
                          ) +
                          " " +
                          new Date(userDetails?.createdAt).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : "N/A"}
                    </div>
                  </div>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Name</div>
                      <div>
                        {userDetails?.firstName&& userDetails?.lastName
                          ? userDetails?.firstName  +" "+ userDetails?.lastName : userDetails?.firstName&& !userDetails?.lastName? userDetails?.firstName : !userDetails?.firstName&& userDetails?.lastName? userDetails?.lastName
                          : "N/A" }
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
												<div className={styles.bodyTitle}>Address</div>
												<div>
													{userDetails?.address
														? JSON.parse(userDetails?.address)?.formatted
														: "N/A"}
												</div>
											</div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Phone Number</div>
                      <div>
                        {userDetails?.phone
                          ? userDetails?.phone
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Email</div>
                      <div>
                        {userDetails?.email ? userDetails?.email : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentContainer}>
                <div className={styles.bodyInfoContainer}>
											<div className={styles.bodyContainer}>
												<div className={styles.bodyTitle}>
													Background Check Status
												</div>
												<div>
													{userDetails?.backgroundCheckStatus
														? userDetails?.backgroundCheckStatus
																?.gestureStatus
															? "Completed and Approved"
															: "Completed and Not Approved"
														: "Not Completed"}
												</div>
											</div>
											<div className={styles.bodyContainer}>
												<div className={styles.bodyTitle}>
													Identity Check Status
												</div>
												<div>
													{userDetails?.identityStatus
														? userDetails?.identityStatus
																?.gestureStatus
															? "Completed and Approved"
															: "Completed and Not Approved"
														: "Not Completed"}
												</div>
											</div>
											<div className={styles.bodyContainer}>
												<div className={styles.bodyTitle}>
													Verification Status
												</div>
												<div>
													{userDetails?.verificationState
														? userDetails?.verificationState
														: "N/A"}
												</div>
											</div>
											<div className={styles.bodyContainer}>
												<div className={styles.bodyTitle}>Online Status</div>
												<div>
													{userDetails?.onlineStatus !== undefined &&
													userDetails?.onlineStatus !== null
														? userDetails?.onlineStatus === 0
															? "Offline"
															: userDetails?.onlineStatus === 1
															? "Paused"
															: userDetails?.onlineStatus === 2
															? "Online"
															: "N/A"
														: "N/A"}
											</div>
										</div>
                  </div>
                </div>
              </div> 
            </ExpandableDiv>
            <CRMNote id={id} />
            <GrunnerUserTasks id = {id}/>
          </>
        ) : (
          <div className={styles.headerContainer}>
            <ArrowBackIcon
              fontSize="large"
              style={{ marginLeft: "-5px", cursor: "pointer" }}
              onClick={handleGoBack}
            />
            <div className={styles.titleContainer}>
              <div className={styles.pageTitle}>
                No information is available
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GrunnerUserDetails;
