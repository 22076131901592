import React, { Component } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';

// import Vendor from './projects/VendorHistory/components/VendorHistory.Component'


import Logistics from './projects/LogisticsDashboard/components/LogisticScreen/LogisticsScreen';
import AdminLogIn from './projects/LogisticsDashboard/components/AdminLogin/AdminLogin';
import OrderDetails from './projects/CRM/components/OrderDetails';
import CRM_Main_Page from './projects/CRM/components/CRM_Main_Page';
import UserDetails from './projects/CRM/components/consumer-user/UserDetails';
import BusinessUserDetails from './projects/CRM/components/business-user/BusinessUserDetails';
import MerchantUserDetails from './projects/CRM/components/merchant-user/MerchantUserDetails'
import GrunnerUserDetails from './projects/CRM/components/grunner-user/GrunnerUserDetails'
import history from './projects/history'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
export default class App extends Component {
  render() {
    return (
    <>
      <ToastContainer limit={1}/>
      <Router history={history}>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/map' />
          </Route>
          <Route path='/login' exact component={AdminLogIn} />
          <Route path='/map' exact component={Logistics} />
          <Route exact path = '/crm' component={CRM_Main_Page}/>
          <Route exact path = '/crm/order-details/:id?' component={OrderDetails}/>
          <Route exact path = '/crm/consumer-user/:id?' component={UserDetails}/>
          <Route exact path = '/crm/business-user/:id?' component={BusinessUserDetails}/>
          <Route exact path = '/crm/merchant-user/:id?' component={MerchantUserDetails}/>
          <Route exact path = '/crm/grunner-user/:id?' component={GrunnerUserDetails}/>
        </Switch>
      </Router>
    </>
    );
  }
}