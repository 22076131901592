import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

import {
  getMerchantUserDetails,
  updateMerchantWithPatchDataByMerchantUid,
} from "../../env/APIManager";
import styles from "./MerchantUserDetails.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import CRMNote from "../crm-notes/crmNote";
import ConfirmationModal from "../../../common/confirmation/ConfirmationModal";

const ExpandableDiv = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const showStyle = {
    height: "auto",
  };
  const hideStyle = {
    paddingTop: "4px",
    paddingBottom: "4px",
  };

  return (
    <div
      style={isExpanded ? showStyle : hideStyle}
      className={styles.collapseSection}
      key={title}
    >
      <div onClick={handleToggle} className={styles.mainTitleContainer}>
        {isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
        <div className={styles.mainTitle}>{title}</div>
      </div>

      {isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
    </div>
  );
};
function MerchantUserDetails() {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [priorityLevel, setPriorityLevel] = useState('');
  const [newPriorityLevel, setNewPriorityLevel] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [tempInventoryData, setTempInventoryData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const key = params.get("key");
  const nextPageToken = params.get("nextPageToken");

  const handleGoBack = () => {
    if (nextPageToken && key) {
      history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
    } else if (key && key !== undefined && key !== null) {
      history.push(`/crm?key=${key}`);
    } else {
      history.push(`/crm`);
    }
  };
  let history = useHistory();

  async function getUser(token, id) {
    setLoading(true);

    let data = await getMerchantUserDetails(token, id);

    if (data?.code === "SUCCESS") {
      setUserDetails(data?.data);
      setLoading(false);
      setPriorityLevel(data?.data?.priorityLevel || '');
    } else if (data?.data?.code === "UNAUTHORIZED") {
      localStorage.clear();
      setLoading(false);
      toast.error(
        "You have been logged out. This may have been caused by using more than one device or browser"
      );
      history.push({ pathname: "/login" });
    } else {
      toast.error("Something went wrong, please try again later");
      setLoading(false);
    }
  }

  function priorityLevelChange(e) {
    setNewPriorityLevel(e?.target?.value);
  }

  async function savePriorityLevel() {
    setLoading(true);
    let data = await updateMerchantWithPatchDataByMerchantUid(
      token,
      id,
      { priorityLevel: newPriorityLevel === 'none' ? null : newPriorityLevel }
    );
    setNewPriorityLevel(null);
    handleServerResponse(data);
    setLoading(false);
    setPriorityLevel(newPriorityLevel);
  }

  useEffect(() => {
    let token =
      localStorage.getItem("token") == null ||
      localStorage.getItem("token") == undefined ||
      localStorage.getItem("token") == ""
        ? ""
        : JSON.parse(localStorage.getItem("token"));
    setToken(token);

    if (!id) {
      if (nextPageToken && key) {
        history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
      } else {
        history.push(`/crm?key=${key}`);
      }
      return;
    }

    getUser(token, id);
  }, []);

  const toReadAbleDaysOfTheWeek = (day) => {
    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "N/A";
    }
  };
  const from24hTo12hTime = (time) => {
    if (time) {
      if (Number(time) > 12) {
        return Number(time) - 12 + ":00 PM";
      } else {
        return Number(time) + ":00 AM";
      }
    } else {
      return "N/A";
    }
  };
  const addInventory = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleChangeTempInventory = (e) => {
    const { name, value } = e.target;
    // Convert value to boolean if the name is "inStock"
    const parsedValue = name === "inStock" ? (value === "true" ? true : value === "false" ? false : null) : value;
    
    setTempInventoryData({
      ...tempInventoryData,
      [name]: parsedValue,
    });
  };
  const handleSaveNewInventory = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (!tempInventoryData) {
      toast.error("Please fill all the fields");
      return;
    }
    if (!tempInventoryData.skuId ||tempInventoryData?.skuId?.trim()?.length<1 ) {
      toast.error("Please fill skuId");
      return;
    }
    if (!tempInventoryData.productName ||tempInventoryData?.productName?.trim()?.length<1 ) {
      toast.error("Please fill productName");
      return;
    }
    if (!tempInventoryData.price) {
      toast.error("Please fill price");
      return;
    }
    if (!tempInventoryData.inStock) {
      toast.error("Please fill inStock");
      return;
    }
    let patchData = { inventory: tempInventoryData };
    let data = await updateMerchantWithPatchDataByMerchantUid(
      token,
      id,
      patchData
    );
    handleServerResponse(data);
  };

  function handleServerResponse(data) {
    if (data?.code === "SUCCESS") {
      setShowModal(false);
      setBtnLoading(false);
      toast.success("Inventory added successfully");
      setTempInventoryData({})
      setUserDetails({ ...userDetails, inventory: data.data.inventory });
    } else if (data?.code === "UNAUTHORIZED") {
      localStorage.clear();
      setBtnLoading(false);
      toast.error(
        "You have been logged out. This may have been caused by using more than one device or browser"
      );
      history.push({ pathname: "/login" });
    } else {
      setBtnLoading(false);
      toast.error("Something went wrong, please try again later");
    }
  }

  return (
    <>
      <ToastContainer limit={1} />
      <div className={styles.mainContainer}>
        {loading ? (
          <div className={styles.clipLoader}>
            <ClipLoader color="#000" size={50} />
          </div>
        ) : userDetails && Object.keys(userDetails)?.length > 0 ? (
          <>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Add Inventory</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>SKUID</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <input
                        type="text"
                        name="skuId"
                        onChange={handleChangeTempInventory}
                        value={tempInventoryData.skuId}
                        className={styles.modalInput}
                      />
                    </div>
                    <div className={styles.modalTitle}>Product Name</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <input
                        type="text"
                        name="productName"
                        onChange={handleChangeTempInventory}
                        value={tempInventoryData.productName}
                        className={styles.modalInput}
                      />
                    </div>
                    <div className={styles.modalTitle}>Price</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <input
                        type="number"
                        name="price"
                        onChange={handleChangeTempInventory}
                        value={tempInventoryData.price}
                        className={styles.modalInput}
                      />
                    </div>
                    <div className={styles.modalTitle}>In Stock?</div>
                    <div className={styles.modalBodyText}>
                      {" "}
                      <select
                        type="select"
                        name="inStock"
                        onChange={handleChangeTempInventory}
                        
                        className={styles.modalInput}
                      >
                        <option value="">Please select</option>
                        <option value={true}>In Stock</option>
                        <option value={false}>Out of Stock</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
				<div className={styles.modalButtonsContainer}>

                <button
                  className={styles.cancelBtn}
                  onClick={() => setShowModal(false)}
				  >
                  Cancel
                </button>
                <button
                  className={styles.confirmBtn}
                  onClick={handleSaveNewInventory}
				  >
                  Save
                </button>
					</div>
              </Modal.Footer>
            </Modal>
            <div className={styles.headerContainer}>
              <ArrowBackIcon
                fontSize="large"
                style={{ marginLeft: "-5px", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              <div className={styles.titleContainer}>
                <div className={styles.pageTitle}>
                  User #{id?.substring(id?.length - 5)}{" "}
                </div>
              </div>
              <ConfirmationModal
                title={`Are you sure you want to update priority level to ${newPriorityLevel}?`}
                onConfirm={savePriorityLevel}
                show={!!newPriorityLevel}
                onCancel={() => setNewPriorityLevel(null)}
              ></ConfirmationModal>
              <div className={styles.headerOptions}>
                <div className={styles.inlineInput}>
                  <label>Priority Level:</label>
                  <select type="select" value={priorityLevel} onChange={priorityLevelChange}>
                    <option value={'none'}>None</option>
                    <option value={1}>Level 1</option>
                    <option value={2}>Level 2</option>
                    <option value={3}>Level 3</option>
                  </select>
                </div>
              </div>
            </div>
            {/* user information section */}
            <ExpandableDiv title="User Information">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyContainer}>
                    <div className={styles.bodyTitle}>Created</div>
                    <div>
                      {userDetails?.createdAt
                        ? new Date(userDetails?.createdAt).toLocaleDateString(
                            "en-US"
                          ) +
                          " " +
                          new Date(userDetails?.createdAt).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : "N/A"}
                    </div>
                  </div>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>First Name</div>
                      <div>
                        {userDetails?.firstName
                          ? userDetails?.firstName
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Last Name</div>
                      <div>
                        {userDetails?.lastName ? userDetails?.lastName : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Business Name</div>
                      <div>
                        {userDetails?.businessName
                          ? userDetails?.businessName
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Phone Number</div>
                      <div>
                        {userDetails?.phoneNumber
                          ? userDetails?.phoneNumber
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Email</div>
                      <div>
                        {userDetails?.email ? userDetails?.email : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        Payment Setup Complete
                      </div>
                      <div>{userDetails?.paymentsSetup ? "Yes" : "No"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </ExpandableDiv>
            <ExpandableDiv title="Business & Special Hours">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyTitle}>Business Hours</div>
                  {userDetails?.business_hours ? (
                    userDetails?.business_hours.map((item, index) => {
                      return (
                        <div key={index} className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainerHours}>
                            <div className={styles.hoursContainer}>
                              <>{toReadAbleDaysOfTheWeek(item.dayOfWeekId)}</>
                              {item.openTime ? (
                                <div>
                                  {from24hTo12hTime(item.openTime)}-
                                  {from24hTo12hTime(item.closeTime)}
                                </div>
                              ) : (
                                <div>
                                  {item.closedAllDay
                                    ? "Closed"
                                    : item.openAllDay
                                    ? "Open All Day"
                                    : "N/A"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainerHours}>
                        <div className={styles.bodyTitle}>
                          No Business Hours
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyTitle}>Special Hours</div>
                  {userDetails?.special_hours ? (
                    userDetails?.special_hours.map((item, index) => {
                      return (
                        <div key={index} className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainerHours}>
                            <div className={styles.hoursContainer}>
                              {new Date(item.date).toUTCString().slice(0, 16)}
                              {item.openTime ? (
                                <div>
                                  {from24hTo12hTime(item.openTime)}-
                                  {from24hTo12hTime(item.closeTime)}
                                </div>
                              ) : (
                                <div>
                                  {item.closedAllDay
                                    ? "Closed"
                                    : item.openAllDay
                                    ? "Open All Day"
                                    : "N/A"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainerHours}>
                        <div className={styles.bodyTitle}>No Special Hours</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ExpandableDiv>
            <ExpandableDiv title="Inventory">
              <div className={styles.containerInventory}>
                <div className={styles.containerButtonInventory}>
                  <button className={styles.addBtn} onClick={addInventory}>
                    Add
                  </button>
                </div>
                {userDetails?.inventory&&  Object.values( userDetails?.inventory)?.length>0 ? (
                  <>
                    <div className={styles.contentContainerTitle}>
                      <div className={styles.bodyTitleInventory}>Inventory</div>
                    </div>
                    <div className={styles.contentContainerInventory}>
                      <div className={styles.bodyTitleInventory}>SKU ID</div>
                      <div className={styles.bodyTitleInventory}>Product</div>
                      <div className={styles.bodyTitleInventory}>Price</div>
                      <div className={styles.bodyTitleInventory}>In Stock?</div>
                    </div>
                    {userDetails?.inventory&&  Object.values( userDetails?.inventory)?.map((item, index) => {
                      return (
                        <div className={styles.contentContainerInventory} key={index}>
							<div className={styles.hoursContainer}>

                          <div className={styles.bodyText}>{item.skuId}</div>
                          <div className={styles.bodyText}>
                            {item.productName}
                          </div>
                          <div className={styles.bodyText}>${item.price}</div>
                          <div className={styles.bodyText}>
                            {item.inStock ? "In Stock" : "Out of Stock"}
                          </div>
							</div>
                        </div>
                      );
                    })}
					<div className={styles.contentContainerInventory}></div>
                  </>
                ) : (<>
                  <div className={styles.contentContainerNoInventory}>
                    <div className={styles.bodyTitleNoInventory}>
                      No Inventory
                    </div>
                  </div>
				<div className={styles.contentContainerNoInventory}></div>
				</>
				  
                )}
              </div>
            </ExpandableDiv>
            <CRMNote id={id} />
            {/* <UserOrders id={id} /> */}
          </>
        ) : (
          <div className={styles.headerContainer}>
            <ArrowBackIcon
              fontSize="large"
              style={{ marginLeft: "-5px", cursor: "pointer" }}
              onClick={handleGoBack}
            />
            <div className={styles.titleContainer}>
              <div className={styles.pageTitle}>
                No information is available
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MerchantUserDetails;
