import React, { useEffect, useState } from "react";
import styles from "./crmNote.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { getCRMNotes, addCrmNote } from "../../env/APIManager";

const ExpandableDiv = ({ title, children, isExpanded, toggleExpand }) => {
	// const [isExpanded, setIsExpanded] = React.useState(false);

	// const handleToggle = () => {
	// 	setIsExpanded((prevState) => !prevState);
	// };

	const showStyle = {
		height: "auto",
	};
	const hideStyle = {
		paddingTop: "4px",
		paddingBottom: "4px",
	};

	return (
		<div
			style={isExpanded ? showStyle : hideStyle}
			className={styles.collapseSection}
			key={title}
		>
			<div onClick={toggleExpand} className={styles.mainTitleContainer}>
				{isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
				<div className={styles.mainTitle}>{title}</div>
			</div>

			{isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
		</div>
	);
};

function CRMNote({ id }) {
	const [loading, setLoading] = useState(false);
	const [notes, setNotes] = useState({});
	const [nextPageToken, setNextPageToken] = useState("");
	const [prevPageToken, setPrevPageToken] = useState("");
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPrevPage, setHasPrevPage] = useState(false);
	const [showNoteModal, setShowNoteModal] = useState(false);
	const [token, setToken] = useState("");
	const [note, setNote] = useState("");
	const [btnLoading, setBtnLoading] = useState(false);

	const [expandDiv, setExpandDiv] = useState(false); // Store expanded state

	let history = useHistory();

	async function handleAPIResponse(res) {
		if (res?.code === "SUCCESS") {
			setNotes(res?.data?.notes);
			setNextPageToken(res?.data?.nextToken);
			setPrevPageToken(res?.data?.prevToken);
			setHasNextPage(res?.data?.hasMore);
			setHasPrevPage(res?.data?.hasLess);
			setLoading(false);
			setBtnLoading(false)
			setNote("")
			setShowNoteModal(false);
		} else if (res?.data?.code === "UNAUTHORIZED") {
			localStorage.clear();
			setLoading(false);
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
			history.push({ pathname: "/login" });
		} else {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setNotes([]);
			setBtnLoading(false)
		}
	}

	const fetchCRMNotes = async (token, string) => {
		let url;
		let data;
		try {
			setLoading(true);
			if (string === "next") {
				url = `/note/all?primaryId=${id}&nextToken=${nextPageToken}`;
			} else if (string === "prev") {
				url = `/note/all?primaryId=${id}&prevToken=${prevPageToken}`;
			} else {
				url = `/note/all?primaryId=${id}`;
			}

			data = await getCRMNotes(token, url);
			await handleAPIResponse(data);
		} catch (error) {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setNotes({});
		}
	};

	const handleNextPage = () => {
		fetchCRMNotes(token, "next");
		setExpandDiv(true);
	};

	const handlePreviousPage = () => {
		fetchCRMNotes(token, "prev");
		setExpandDiv(true);
	};

	const handleFirstPage = () => {
		fetchCRMNotes(token);
		setExpandDiv(true);
	};

	useEffect(() => {
		let token =
			localStorage.getItem("token") == null ||
			localStorage.getItem("token") == undefined ||
			localStorage.getItem("token") == ""
				? ""
				: JSON.parse(localStorage.getItem("token"));
		setToken(token);

		fetchCRMNotes(token);
	}, []);

	function displayNoteModal() {
		setShowNoteModal(true);
	}

	const handleAddNote = async () => {
		if (note?.trim() === "") {
			toast.error("Please type in a note before submitting!");
			return;
		}
		try {
			setBtnLoading(true);

			let data = await addCrmNote(token, id, note);
			await handleAPIResponse(data);
		} catch (error) {
			setBtnLoading(false);
			toast.error("Something went wrong, please try again later");
			
		}
	};

	const handleNoteInput = (event) => {
		const inputValue = event.target.value;
		if (inputValue.trim() !== "") {
			setNote(inputValue);
		} else {
			setNote("");
		}
	};

	return (
		<>
			<ToastContainer limit={1}/>
			<div className={styles.mainContainer}>
				{loading ? (
					<div className={styles.clipLoader}>
						<ClipLoader color="#000" size={50} />
					</div>
				) : (
					<>
						<ExpandableDiv
							title="Notes"
							isExpanded={expandDiv}
							toggleExpand={() => setExpandDiv(!expandDiv)}
						>
							<>
								<div className={styles.addNoteBtnContainer}>
									<button
										onClick={displayNoteModal}
										className={styles.actionBtn}
									>
										Add Note
									</button>
								</div>
								<Modal
									show={showNoteModal}
									animation={true}
									style={{ marginTop: "20vh", paddingRight: "0" }}
								>
									<Modal.Body>
										<div
											style={{
												display: "flex",
												backgroundColor: "#fff",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
												padding: "10px !important",
												width: "80%",
												margin: "auto",
											}}
										>
											<div
												style={{
													fontFamily: "Mulish",
													fontSize: "18px",
													fontWeight: "700",
												}}
											>
												Type in your note
											</div>
											<div>
												<textarea
													type="text"
													placeholder="Type in your note..."
													value={note}
													onChange={handleNoteInput}
													className={styles.input}
												/>
											</div>
										</div>
									</Modal.Body>

									<Modal.Footer
										style={{
											display: "flex",
											backgroundColor: "#fff",
											justifyContent: "center",
										}}
									>
										<button
											className={styles.cancelBtn}
											onClick={() => setShowNoteModal(false)}
											disabled={btnLoading}
										>
											Cancel
										</button>

										<button
											className={styles.confirmBtn}
											onClick={handleAddNote}
											disabled={btnLoading}
										>
											{btnLoading ? (
												<div className={styles.btnClipLoader}>
													<ClipLoader color="#fff" size={20} />
												</div>
											) : (
												"Add"
											)}
										</button>
									</Modal.Footer>
								</Modal>
								{notes && notes?.length > 0 ? (
									<>
										{notes?.map((note) => (
											<div key={note?.id} className={styles.container}>
												<div className={styles.contentContainer}>
													<div className={styles.bodyInfoContainer}>
														<div className={styles.bodyContainer}>
															<div className={styles.bodyTitle}>
																Created Time
															</div>
															<div>
																{note?.createdTime
																	? new Date(
																			note?.createdTime
																	  ).toLocaleDateString("en-US") +
																	  " " +
																	  new Date(
																			note?.createdTime
																	  ).toLocaleTimeString([], {
																			hour: "2-digit",
																			minute: "2-digit",
																	  })
																	: "N/A"}
															</div>
														</div>
														<div className={styles.bodyContainer}>
															<div className={styles.bodyTitle}>Created By</div>
															<div>
																{note.createdBy ? note.createdBy : "N/A"}
															</div>
														</div>

														<div className={styles.bodyContainer}>
															<div className={styles.bodyTitle}>Note</div>
															<div>{note?.note ? note?.note : "N/A"}</div>
														</div>
													</div>
												</div>
											</div>
										))}
										{!hasPrevPage && !hasNextPage ? null : (
											<div className={styles.btnContainer}>
												{hasPrevPage && (
													<Button onClick={handleFirstPage}>
														&lt;&lt; First
													</Button>
												)}
												{hasPrevPage && (
													<Button onClick={handlePreviousPage}>
														&lt; Previous
													</Button>
												)}
												{hasNextPage && (
													<Button onClick={handleNextPage}>Next &gt;</Button>
												)}
											</div>
										)}
									</>
								) : (
									<div className={styles.noDataContainer}>
										<div className={styles.contentContainer}>
											<div className={styles.bodyInfoContainer}>
												<div className={styles.bodyContainer}>
													<div className={styles.bodyTitle}>
														There are no notes.
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</>
						</ExpandableDiv>
					</>
				)}
			</div>
		</>
	);
}

export default CRMNote;
