import "./Map.css";
import MapStyle from "./MapStyle";
import styles from "./Map.module.css";
import ClusterStyle from "./ClusterStyle";
import { useEffect, useState } from "react";
import recipientIcon from "../../assets/RecipientIcon.png";
import runnerIcon from "../../assets/RunnerIcon.png";
import TaskWindow from "./TaskWindow/TaskWindow";
import ClipLoader from "react-spinners/ClipLoader";
import GrunnerWindow from "./InfoWindow/InfoWindow";
import ControlWindow from "./ControlWindow/ControlWindow";
import { getAllGRunners } from "../../env/APIManager";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { toast } from "react-toastify";

import {
	GoogleMap,
	MarkerF,
	MarkerClusterer,
	InfoWindowF,
	OverlayViewF,
} from "@react-google-maps/api";
import { red, yellow } from "@mui/material/colors";
import { useHistory } from "react-router-dom";

export default function Map() {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	// const tokenId = params.get("tokenId")

	const { push } = useHistory();
	const [map, setMap] = useState(null);
	const [gRunners, setGRunners] = useState([]);
	const [unfilteredGRunners, setUnfilteredGRunners] = useState([]);
	const [runnerId, setRunnerId] = useState("");
	const [taskId, setTaskId] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [focused, setFocused] = useState(false); // grunner bar is open when true
	const [taskFocused, setTaskFocused] = useState(false);
	const [taskInfo, setTaskInfo] = useState(null);
	const [infoVisible, setInfoVisible] = useState({});
	const [destinationLoaded, setDestinationLoaded] = useState({});
	const [destMarker, setDestMarker] = useState(null);
	const [selected, setSelected] = useState({ index: null });
	const [assignedTasks, setAssignedTasks] = useState(null);
	const [unfilteredTasks, setUnfilteredTasks] = useState([]);
	const [marker, setMarker] = useState(null); // this state is set when you need to display a marker not displayed in the current filter settings.
	const [filteredTaskMarkers, setFilteredMarkers] = useState(null);
	const [checked, setChecked] = useState({ pickup: false, dropoff: true });
	const [checkLoaded, setCheckLoaded] = useState(false);
	const [runnerChecked, setRunnerChecked] = useState({
		all: true,
		online: false,
		offline: false,
	});
	const [center, setCenter] = useState({
		lat: 40.32662241903947,
		lng: -91.91243062537832,
	});
	const mapBounds = { north: 85, south: -85, west: -180, east: 180 };
	const containerStyle = {
		width: "100%",
		height: "100%",
	};

	const markerClick = async (runner) => {
		setRunnerId(runner.id);
		setFocused(true);
		setMarker(runner);
	};

	const handleClick = async (taskInfo) => {
		setTaskId(taskInfo?.id);
		setTaskFocused(true);
		setTaskInfo(taskInfo);
		// pan to task when you click on a unassigned task
		if (map != null)
			map?.panTo({ lat: taskInfo?.addressLat, lng: taskInfo?.addressLng });
	};

	const clearWindow = async () => {
		setFocused(false);
		setAssignedTasks(null);
		// marker.setMap(null);
		setMarker(null);
		clearFocus();
	};

	// passing a true boolean into the clearFocus function prevents the function from removing the newly assigned or removed icon from the map.
	// checked dropoff must also be false to prevent task deselection from clearing a marker.
	const clearFocus = (recentAssign) => {
		setTaskFocused(false);
		setSelected({ index: null });
		if (destMarker != null && !recentAssign) {
			setTaskInfo(null);
			if (!checked.dropoff) {
				destMarker.setMap(null);
				setDestMarker(null);
			}
		}
	};

	const onlineStatus = (status) => {
		var icon;
		if (status === 2) {
			icon = <CircleRoundedIcon fontSize="small" color="success" />;
		} else if (status === 1) {
			icon = <CircleRoundedIcon fontSize="small" sx={{ color: yellow[700] }} />;
		} else {
			icon = <CircleRoundedIcon fontSize="small" sx={{ color: red[500] }} />; // may need to handle an undefined result here
		}
		return icon;
	};

	const panToGrunner = (grunnerUid) => {
		var runner = gRunners?.find((runner) => runner?.id === grunnerUid);

		if (!runner) {
			var runner = unfilteredGRunners?.find(
				(runner) => runner?.id === grunnerUid
			);
		}
		if (runner) {
			markerClick(runner);
			if (map != null)
				map?.panTo({
					lat: runner?.recentLocation?.latitude,
					lng: runner?.recentLocation?.longitude,
				});
		}
	};

	const gRunnerMarkers = (runner, index) => {
		const i = index !== undefined ? index + 1 : 0;
		return (
			<MarkerF
				key={index}
				zIndex={14}
				position={{
					lat: runner.recentLocation.latitude,
					lng: runner.recentLocation.longitude,
				}}
				onClick={() => markerClick(runner)}
				onLoad={() => {
					setInfoVisible((prev) => ({ ...prev, [i]: true }));
				}}
				onUnmount={() => {
					setInfoVisible((prev) => ({ ...prev, [i]: false }));
				}}
				icon={{
					url: runnerIcon,
					scaledSize: {
						width: 35,
						height: 35,
					},
				}}
				label={{ className: styles.markerShadowLabel, text: " " }}
			>
				{grunnerNameWindow(runner)}
			</MarkerF>
		);
	};

	const grunnerNameWindow = (runner) => {
		return runner?.firstName || runner?.lastName ? (
			<InfoWindowF
				zIndex={15}
				options={{ disableAutoPan: true }}
				position={{
					lat: runner.recentLocation.latitude,
					lng: runner.recentLocation.longitude,
				}}
			>
				<div
					className={[styles.container, styles.noselect].join(" ")}
					onClick={() => markerClick(runner)}
				>
					<div className={styles.card}>
						<div>
							{runner?.firstName} {runner?.lastName}
						</div>
						<div>{onlineStatus(runner?.onlineStatus)}</div>
					</div>
				</div>
			</InfoWindowF>
		) : null;
	};

	const destinationMarker = (taskInfo, index) => {
		const i = index !== undefined ? index + 1 : 0; // this function is also called by a mapping function & individually one time so the 0 index will be unreachable for maps
		return (
			<OverlayViewF
				key={i}
				mapPaneName="markerLayer"
				position={{ lat: taskInfo?.addressLat, lng: taskInfo?.addressLng }}
			>
				<div className={styles.markerShadow}>
					<MarkerF
						zIndex={15}
						position={{ lat: taskInfo?.addressLat, lng: taskInfo?.addressLng }}
						icon={{
							url: recipientIcon,
							scaledSize: {
								width: 35,
								height: 35,
							},
						}}
						onLoad={(marker) => {
							// only the
							if (i === 0) {
								setDestMarker(marker);
							}
							setDestinationLoaded((prev) => ({ ...prev, [i]: true }));
						}}
						onUnmount={() => {
							setDestinationLoaded((prev) => ({ ...prev, [i]: false }));
						}}
					>
						<InfoWindowF
							zIndex={15}
							options={{ disableAutoPan: true }}
							position={{
								lat: taskInfo?.addressLat,
								lng: taskInfo?.addressLng,
							}}
						>
							<div
								className={[styles.runnerContainer, styles.noselect].join(" ")}
							>
								<div className={styles.runnerCard}>
									<div>{taskInfo?.recipientName}</div>
									<div>{taskInfo?.address}</div>
									<div>
										{taskInfo?.addressExtra && taskInfo?.addressExtra !== "N/A"
											? taskInfo?.addressExtra
											: null}
									</div>
								</div>
							</div>
						</InfoWindowF>
					</MarkerF>
				</div>
			</OverlayViewF>
		);
	};

	const filterMarkers = () => {
		// if unchecked and assigned tasks for grunner is up show the assigned tasks
		// else just return the full Grunner list which can only be shown if checked
		if (!checked.dropoff && focused) {
			return assignedTasks?.map((task, index) =>
				destinationMarker(task, index)
			);
		} else if (checked.dropoff) {
			return unfilteredTasks?.map((task, index) =>
				destinationMarker(task, index)
			);
		}
	};

	const gRunnersMapped = () => {
		return gRunners?.map((runner, index) => gRunnerMarkers(runner, index));
	};

	const filterGrunners = (runners) => {
		const filterStatus = Object?.keys(runnerChecked)
			?.find((key) => runnerChecked[key] === true)
			.toString();

		var filteredRunners;
		if (filterStatus === "online") {
			filteredRunners = runners?.filter((runner) => runner?.onlineStatus == 2);
		} else if (filterStatus === "offline") {
			filteredRunners = runners?.filter((runner) => runner?.onlineStatus == 0);
		} else {
			filteredRunners = runners;
		}

		return filteredRunners;
	};
	let tokenId =
		localStorage.getItem("token") == null ||
		localStorage.getItem("token") == undefined ||
		localStorage.getItem("token") == ""
			? ""
			: JSON.parse(localStorage.getItem("token"));

	useEffect(() => {
		if(tokenId){
		const intervalRunner = setInterval(() => {
			getAllGRunners(tokenId).then((response) => {
				
				if (response?.code === "SUCCESS") {
					const gRunners = response?.data?.grunners;
					const validRunners = gRunners.filter(
						(runner) =>
							runner.recentLocation?.longitude !== undefined &&
							runner.recentLocation?.latitude !== undefined
					);
					setUnfilteredGRunners(validRunners);
					setGRunners(filterGrunners(validRunners));
					setCheckLoaded(false);
					setLoaded(true);
					
				} else if (response?.data?.code === "UNAUTHORIZED") {
					localStorage.clear();
					push({ pathname: "/login" });
					toast.error(
						"You have been logged out. This may have been caused by using more than one device or browser"
					);
				}
			});
		}, 5000);

		return () => {
			clearInterval(intervalRunner);
		};
	}
	else{
		push({ pathname: "/login" });
					toast.error(
						"You have been logged out. This may have been caused by using more than one device or browser"
					);
	}
	}, [runnerChecked]);

	useEffect(() => {
		setFilteredMarkers(filterMarkers());
	}, [checked, unfilteredTasks, assignedTasks]);

	return (
		<>
			{!loaded ? (
				<div className={styles.loaderContainer}>
					<ClipLoader color="#6C40FC" size={50} />
				</div>
			) : (
				<div className={styles.mapContainer}>
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={center}
						zoom={4}
						onClick={clearWindow}
						onLoad={(map) => setMap(map)}
						options={{
							disableDefaultUI: true,
							styles: MapStyle,
							clickableIcons: false,
							restriction: {
								latLngBounds: mapBounds,
								strictBounds: true,
							},
						}}
					>
						{gRunnersMapped()}

						{marker && gRunnerMarkers(marker)}

						<TaskWindow
							tokenId={tokenId}
							handleClick={handleClick}
							taskFocused={taskFocused}
							clearFocus={clearFocus}
							setUnfilteredTasks={setUnfilteredTasks}
							selected={selected}
							setSelected={setSelected}
							panToGrunner={panToGrunner}
						/>

						{taskInfo && !checked.dropoff && destinationMarker(taskInfo)}

						{focused && (
							<GrunnerWindow
								runnerId={runnerId}
								tokenId={tokenId}
								taskId={taskId}
								taskFocused={taskFocused}
								clearFocus={clearFocus}
								setAssignedTasks={setAssignedTasks}
							/>
						)}
						{filteredTaskMarkers}

						<ControlWindow
							tokenId={tokenId}
							checked={checked}
							runnerChecked={runnerChecked}
							unfilteredTasks={unfilteredTasks}
							setRunnerChecked={setRunnerChecked}
							setChecked={setChecked}
							destinationMarker={destinationMarker}
							checkLoaded={checkLoaded}
							setCheckLoaded={setCheckLoaded}
						/>
					</GoogleMap>
				</div>
			)}
		</>
	);
}
